.text-block {
    text-align: center;
}

.text-block__content {
    display: inline-block;
    width: 50%;
}

.text-block__header {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 32px;
}

.text-block__body {
    font-size: 24px;
    color: #5f6369;
    font-family: 'Montserrat', sans-serif;
}