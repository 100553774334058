.banner {
    background-image: url("../../../assets/hh-banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    text-align: left;
    height: 40vh;
}

.banner img {
    position: absolute;
    z-index: 9;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    width: 175px;
}

.alt-banner {
    background-color: black;
    text-align: left;
    height: 40vh;
}

.alt-banner img {
    position: absolute;
    z-index: 9;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    width: 175px;
}

.banner__page-label {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 11rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-size: 3rem;
    font-weight: 700;
    position: absolute;
    z-index: 8;
}

.banner__cta-container-wrapper {
    display: inline-block;
    margin-top: 8rem;
    width: 100%;
    text-align: center;
}

.banner__body-cta-container {
    margin-bottom: 48px;
    display: inline-block;
    padding: 0 10%
}

.banner__body--cta {
    color: black;
    background-color: #F1E14A;
    font-weight: 600;
    font-size: 24px;
    padding: 12px;
    vertical-align: middle;
    display: block;
    min-width: 200px;
    text-decoration: none;
}

.banner__body--cta:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 1px;
}

@media (min-width: 40rem) {
    .banner {
        height: 490px;
        background-position-y: -250px;
    }

    .banner__home {
        height: 700px;
    }

    .banner img {
        margin-left: 24px;
        margin-top: 24px;
    }

    .banner__page-label {
        margin-top: 225px;
    }

    .banner__cta-container-wrapper {
        margin-top: 600px;
    }

    .banner__body-cta-container {
        padding: 0px 10px;
    }

}