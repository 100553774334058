.photo-cube {
    /* background-color: green; */
    overflow: hidden;
}

.photo-cube img {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
}



.photo-cube.photo-cube__stackable {
    width: 250px;
    display: inline-block;
}

/* .photo-cube.photo-cube__half {
    width: 50%;
} */

@media (min-width: 40rem) {
    .photo-cube {
        height: 400px;
    }

    .video-cube {
        /* video cube style over rides photo cube due to order*/
        height: 100%;
    }

    .photo-cube.photo-cube__third {
        width: 250px;
        height: 250px;
    }
}