.events-main {
    background-color: #EE5E57;
    min-height: 100vh;
}

.event-container__cta-wrapper {
    padding-left: 1rem;
}

.events__waiver-button-container {
    text-align: center;
    background-color: white;
    padding: 1rem 0rem;
}

.event-container__event-closed-text {
    color: white;
    text-align: center;
    text-decoration: underline;
}