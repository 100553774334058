.one-third-photo-text {
    display: flex;
    flex-direction: column;
}

.one-third-photo-text__left {
    vertical-align: top;
    color: white;
}

@media (min-width: 40rem) {
    .one-third-photo-text__right-content p {
        margin: 0rem 0rem 0.5rem 0rem;
    }

    .one-third-photo-text__left {
        display: inline-block;
    }

    .one-third-photo-text {
        text-align: left;
        display: inline-block;
    }

    .one-third-photo-text__right {
        display: inline-block;
        width: calc(100% - 400px);
    }

    .one-third-photo-text__right-content-wrapper{
        padding: 40px 100px;
    }
}