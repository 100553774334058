.btn-styled-69170 { 
    background: #e1bc23 !important;
    font-family: Arial, Helvetica, sans-serif !important; 
    text-align: center !important;
    font-size: 1.125rem !important; 
    font-weight: bold !important; 
    text-decoration: none !important;
    color: #fff !important;
    border-radius: 5px !important; 
    border: none !important; 
    cursor: pointer !important;
    padding: 15px 30px !important; 
    max-width: 250px !important; 
    margin: 0 !important; 
}

.btn-styled-69170:hover {
    filter: brightness(0.75); 
}

.gala-main {
    min-height: 100vh;
    background-color: #010202;
    padding-top: 2.5rem;
}

.gala-main__images-mobile {
    margin-top: 2rem;
    text-align: center;
    background-color: white;
    margin-bottom: 1rem;
}

.gala-main__qgiv-container {
    text-align: center;
    margin-bottom: .5rem;
}

.gala-main__images {
    text-align: center;
}

.gala-main__images-mobile img {
    max-width: 90%;
    max-height: 100%;
}

.gala-main__images {
    display: none;
}

.gala-main__sponsor-content {
    text-align: center;
    background-color: white;
    margin-bottom: 1rem;
}

.gala-main__sponsor-content-white {
    text-align: center;
    background-color: white;
    margin: 1rem 0rem;
}

.gala-main__sponsor-content-black {
    text-align: center;
    background-color: black;
    padding: 1rem 0rem;
}

.gala-main__sponsor-content img {
    width: 100%;
}

.gala-main__sponsor-content span {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 4rem;
    color: #EE5E57;
}

@media (min-width: 40rem) {

    .gala-main__images-mobile {
        display: none;
    }

    .gala-main__images {
        display: block;
        text-align: center;
        margin-top: 3rem;
        background-color: white;
        margin-bottom: .5rem;
    }

    .gala-main__images img {
        max-width: 87vh;
        max-height: 100%;
    }

    .gala-main__sponsor-content img {
        width: 70%;
    }
}