
/* rolling image banner, will be updated regularly*/

.home-content {
    text-align: center;
}

.home-contnet__body {
    display: inline-block;
    padding-top: 5rem;
    width: 100%;
}


.home-contnet__body a {
    text-decoration: none;
}

.home-contnet__body p {
    margin: 0px;
    margin-bottom: 32px;
    /* removed 7/8 for conflicts with dynamic color
    color: #5f6369; */
    font-family: 'Montserrat', sans-serif;
}

.home-contnet__body-name { 
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #EE5E57;
}

.home-contnet__body-announcement { 
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 4rem;
    color: #EE5E57;
}

.home-content__body-small {
    font-weight: 300;
    font-size: 1rem;
}

.home-content__body-large {
    font-weight: 400;
    font-size: 1.5rem;
}

.home-content__body-cta-container {
    margin-bottom: 48px;
    display: inline-block;
    padding: 0px 10px;
}

.home-content__body--cta {
    color: black;
    background-color: #F1E14A;
    font-weight: 600;
    font-size: 24px;
    padding: 12px;
    vertical-align: middle;
    border-radius: 12px;
}


.home-content__button-container {
    text-align: center;
    margin-top: 24px;
}

.home-content__button {
    border: 0px;
    background-color: #1F5883;
    color: white;
    font-size: 24px;
    padding: 12px;
}

.home-content__give-block {
    /* background-color: pink; */
}

@media (min-width: 40rem){
    .home-contnet__body {
        padding-top: 1.9rem;
    }
    
    .home-content__body-large {
        font-size: 2rem;
    }

    .home-content__padded {
        margin: 4rem 5rem;
    }

    .home-content__give-block-action-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

