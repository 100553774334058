.video-frame__content {
    width: 100%;
}

@media (min-width: 40rem) {
    .video-frame__content {
        height: 500px;
    }
    
    .video-frame__wrapper {
        /* background-color: #1F5883; */
        height: 500px;
    }
}