/* header content*/
.header-main { 
    width: 100%;
    position: absolute; 
    z-index: 9; 
    text-align: right; 
    font-family: 'Montserrat', sans-serif; 
    font-weight: 600; 
    font-size: 25px; 
    color: white;
} 

/* div containing header list*/
.header-nav { 
    vertical-align: middle; 
    padding-right: 24px;
}
 
/* header link ul */
.header-nav__items {
    display: none;
}

/* don't display logo image in mobile*/
.header-main__logo-container > img {
    display: none;
}

.header-main__give-container {
    display: none;
}

@media (min-width: 40rem) {

    .header-main__logo-container > img {
        display: inline-block;
        width: 150px;
        float: left;
        padding: 1rem 1rem 1rem 4rem;
    }

    .header-main {
        /* padding: 0px 16px; */
        transition-duration: 1s;
        text-align: center;
    }

    .header-main.scrolling {
        background: rgba(255,255,255, 0.85);
        position: fixed;
    }

    .header-main.scrolling .header-nav__items {
        color: #EE5E57;
    }

    .header-main__give-container {
        display: block;
        float:right;
        padding: 1rem 4rem 1rem 1rem;
    }

    .header-main__give-container > a {
        background-color: #EE5E57;
        padding: .25rem .75rem;
        transition: 1s;
    }

    .header-main__give-container > a.scrolling {
        background-color: #1F5883; 
    }

    .header-nav {
        display: inline-block;
    }

    .header-nav__items { 
        display:inline-block; 
        padding-inline-start: 0;
        margin: 0px;
    } 

    .header-nav__mobile-menu {
        display: none;
    }
}

/* header links formatting */
.header-nav__item {
    display: inline-block; 
    padding: 1rem; 
    vertical-align: top;
}

/* hover over behaviour old orange - fd4f54*/
.header-nav__item:hover {
    background-color: #1F5883; 
    color: white;
}

/* styling the link items*/
.header-nav__item a {
    text-decoration: none; 
}