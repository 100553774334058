.hh-coral-background {
    background: #EE5E57
}

.hh-dark-blue-background {
    background: #1F5883
}

.hh-light-blue-background {
    background: #56B9E6;
}

.hh-yellow-background {
    background: #F1E14A
}

.hh-white-background {
    background: white;
}

.hh-gray-background {
    background: #5f6369;
}