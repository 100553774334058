.content-body {
    font-size: 1.3rem;
    font-family: 'Montserrat', sans-serif;
}

.event-body {
    font-size: 1.3rem;
    font-family: 'Montserrat', sans-serif;
}

.event-list-body {
    padding: 0rem 1rem;
}

@media (min-width: 40rem) {
    .content-body {
        font-size: 1.5rem;
    }
}