.volunteer-form-main {
    display: inline-block;
}

.volunteer-form {
    /* margin-bottom: 48px; */
}

.volunteer-form__container {
    display: inline-block;
    width: 100%
}

.volunteer-form__container-form-wrapper {
    background-color: #fd4f54;
}

.volunteer-form__container form {
    background-color: #fd4f54;
    padding: 1rem;
    color: white;
    text-align: left;
    display: inline-block;
}

.volunteer-form__container input, label {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    border: none;
    font-size: 1.5rem;
}

.volunteer-form__input-textarea {
    height: 100px;
}

.volunteer-form__name-container .volunteer-form__item {
    display: inline-block;
}

.volunteer-form__item {
    padding: 10px 10px 0px 10px;
    width: 150px;
    display: block;
    font-size: 18px;
}

.volunteer-form__item-textarea-container {
    width:90%;
    max-width: 650px;
}

.volunteer-form__cta-container {
    text-align: center;
    margin-top: 24px;
}

.volunteer-form__cta {
    border: 0px;
    background-color: #1F5883;
    color: white;
    font-size: 24px;
    padding: 12px;
}

.volunteer-form__checkbox-wrapper {
    display: flex;
    flex-direction: column;
    width: 100vw;
}

.volunteer-form__checkbox-input.volunteer-form__checkbox-input {
    width: 25px;
}

.volunteer-form__checkbox {
    display: flex;
}

.volunteer-form__checkbox-label {
    float: left;
}

@media (min-width: 40rem) {
    
    .volunteer-form__container form {
        padding: 3.1rem;
        width: 60%;
    }

    .volunteer-form__container input, label {
        font-size: 1rem;
    }

    .volunteer-form__item-textarea-container {
        width:75%;
        max-width: 650px;
    }
    .volunteer-form__checkbox-input.volunteer-form__checkbox-input {
        /* width: auto; */
    }

}