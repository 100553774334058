.drop-down-menu {
    background-color: #1F5883;
    color: white;
    position: absolute;
    right: 0rem;
    top: 5rem;
    padding-right: 3rem;
    }

.drop-down-menu__items {
    list-style: none;
    text-align: left;
}

.drop-down-menu__items li:hover {
    text-decoration: underline;
}

@media (min-width: 40rem) {

    .drop-down-menu {
        position: static;
        min-width: auto;
        width: auto;
        padding-right: .5rem;
    }
}

