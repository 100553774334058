
.volunteer-main {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    background-image: url('../../assets/hh-heart-background.png');
    background-repeat: no-repeat;
    background-position-y: 400px;
    background-position-x: -100px;
    background-size: 500px;
    background-attachment: fixed;
}

.volunteer-form__container .form-title {
    text-align: center;
}