.content-list {
    font-size: 1.3rem;
    color: #5f6369;
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    list-style-type: none;
    margin-top: 0px;
    padding: 0px;
}

@media (min-width: 40rem) {
    .content-list {
        font-size: 1.5rem;
    }
}