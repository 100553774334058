.giving-main{
    background-repeat: no-repeat;
    background-position-y: 400px;
    background-position-x: -100px;
    background-size: 500px;
    background-attachment: fixed;
    text-align: center;
}

.giving-main__content {
    text-align: center;
}

.giving-main__content-body{
    display: inline-block;
    width: 90%;
}

.giving-main__body-cta-container {
    margin-bottom: 48px;
    display: inline-block;
    padding: 0px 10px;
}

.giving-main__body--cta {
    color: black;
    background-color: #F1E14A;
    font-weight: 600;
    font-size: 24px;
    padding: 12px;
    vertical-align: middle;
    display: block;
    min-width: 200px;
    text-decoration: none;
}

.giving-main__body--cta:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 1px;
}

@media (min-width: 40rem) {
    
    .giving-main {
        background-image: url('../../assets/hh-heart-background.png');
    }

    .giving-main__content-body {
        width: 60%;
    }
}