.footer-main {
    text-align: center;
    height: 82px;
    background-color: #1F5883;
    color: white;
    padding-top: 8px;
    /* position:absolute; */
    left:0;
    bottom:0;
    right:0;
}

.footer-main p {
    margin: 8px;
}

.footer-main__contact-links-container {
    /* margin-right: 40px; */
}

.footer-main__items {
    padding: 0px;
    margin: 0px;
}

.footer-main__item {
    display: inline-block;
    padding: 0px 4px;
}


.footer-main__contact-link img {
    width: 25px;
    height: 25px;
}