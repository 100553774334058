.events-registration__button-container {
    text-align: center;
    margin-top: 24px;
}

.events-registration__button {
    border: 0px;
    background-color: #1F5883;
    color: white;
    font-size: 24px;
    padding: 12px;
}

.events-registration__parking-map-container img {
    width: 100%;
}

.events-registration__parking-map-container {
    text-align: center;
}

.events-registration__location {
    text-align: center;
}

.events-registration__location address {
    font-size: 1.3rem;
    font-family: 'Montserrat', sans-serif;
    color: #5f6369;
}

.events-registration__welcome-message {
    text-align: center;
}

.events-registration__registration-header {
    text-align: center;
}

.events-registration__event-closed-text {
    text-decoration: underline;
    text-align: center;
}

@media (min-width: 40rem) {
    .events-registration__location address {
        font-size: 1.5rem;
    }
}