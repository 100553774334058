.half-photo-text {
    display: flex;
    flex-direction: column;
}


.half-photo-text__left {
    vertical-align: top;
    order: 1;
}

.half-photo-text__right {
    text-align: center;
    vertical-align: center;
    order: 2;
}



@media (min-width: 40rem) {
    .half-photo-text {
        display: block;
    }
    .half-photo-text__left {
        display: inline-block;
        width: 50%;
        order: unset;
    }

    .half-photo-text__left.video {
        min-width: 625px;
    }

    .half-photo-text__right {
        display: inline-block;
        width: 50%;
        order: unset;
    }

    .half-photo-text__right-content-wrapper {
        padding: 50px;
    }
}