.giving-form-main {
    display: inline-block;
}

.giving-form {
    text-align: center;
    display: inline-block;
    min-width: 400px;
    vertical-align: top;
}

.giving-form__container {
    padding: 25px;
}

.giving-form__container form {
    background-color: #fd4f54;
    padding: 50px;
}


.giving-form__container input, label {
    display: block;
}
