.hover-photo-block-main {
    max-width: 600px;
    /* height: 350px; */
    display: inline-block;
    overflow: hidden;
    position: relative;
}

.hover-photo-block_overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #1F5883;
}

.hover-photo-block_text {
    color: white;
    font-size: 20px;
    padding-top: 50vh;
    /* position: absolute;
    top: 25% */
    /* top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%); */
}

.hover-photo-block_overlay:hover{
    opacity: 0.5;
}

/* .hover-photo-block-main:hover {
    opacity: 0.5;
} */

.hover-photo-block-main img {
    /* width: 350px; */
    height: 100%;
}