.video-content {
    object-fit: none;
    height: 40vh;
    width: 100%;
    position: absolute;
}

@media (min-width: 40rem) {
    .video-content {
        height: 500px;
    }
}