.mission-content {
    text-align: center;
    background-repeat: no-repeat;
    background-position-y: 400px;
    background-position-x: -100px;
    background-size: 500px;
    background-attachment: fixed;
}

.mission-content__lead-paragraph-wrapper {
    /* background-color: black; */
}

.mission-content__lead-paragraph{
    width: 90%;
    display: inline-block;
}

.mission-content__lead-paragraph-text {
    font-size: 1.3rem;
    color: #5f6369;
    font-family: 'Montserrat', sans-serif;
}

.mission-content__lead-paragraph-text-highlight {
    color: #EE5E57;
}

.mission-content img {
    width: 100%;
    height: auto;
}

@media (min-width: 40rem) {
    .mission-content {
        background-image: url('../../assets/hh-heart-background.png');
    }

    .mission-content__lead-paragraph {
        padding-top: 2rem;
    }

    .mission-content__lead-paragraph-text {
        font-size: 1.5rem;
    }
}