.hh-coral {
    color: #EE5E57
}

.hh-dark-blue {
    color: #1F5883
}

.hh-light-blue {
    color: #56B9E6;
}

.hh-yellow {
    color: #F1E14A
}

.hh-white {
    color: white;
}

.hh-gray {
    color: #5f6369;
}

.hh-black {
    color: black;
}