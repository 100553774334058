.photo-text-vertical-stack__wrapper {
    display: flex;
    flex-direction: column;
}

.photo-text-vertical-stack__block img {
    height: 100%;
    width: 100%;
    background: #1F5883;
}

@media (min-width: 40rem){
    .photo-text-vertical-stack__wrapper {
        width: calc(100%/3 - 5%);
    }
}
